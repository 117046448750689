<script>
  export let width = "80px";
  export let height = "50px";
</script>

<svg {width} {height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(1.000000, 2.000000)">
      <path
        d="M9.29,1.86 L0.82,16 C0.464641628,16.6153996 0.462519934,17.3731469 0.81442652,17.9905269 C1.16633311,18.6079069 1.81941202,18.9921898 2.53,19 L19.47,19 C20.180588,18.9921898 20.8336669,18.6079069 21.1855735,17.9905269 C21.5374801,17.3731469 21.5353584,16.6153996 21.18,16 L12.71,1.86 C12.3474343,1.26228132 11.6990862,0.897255995 11,0.897255995 C10.3009138,0.897255995 9.65256566,1.26228132 9.29,1.86 Z"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11,7 L11,11"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle fill="#000000" cx="11" cy="15" r="1" />
    </g>
  </g>
</svg>
