<script context="module">
  export const adjustDataTypes = {
    // used for label description
    Advertising: "Information about Advertising",
    Installation: "Information about how the application was installed",
    Application:
      "Information about the version and build of the application and its bundle ID",
    Device: "Information about the make and model of your device",
    User: "Information about the user",
  };
</script>
