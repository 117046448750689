<script>
  const rev = "__VERSION__";
  const lastUpdatedTime = "__LAST_UPDATED_TIME__";
</script>

<footer class="mzp-c-footer">
  <div class="mzp-l-content">
    <nav class="mzp-c-footer-secondary">
      <div class="mzp-c-footer-primary-logo">
        <a
          href="https://www.mozilla.org/"
          data-link-type="footer"
          data-link-name="Mozilla">Mozilla</a
        >
      </div>
      <div class="mzp-c-footer-legal">
        <ul class="mzp-c-footer-terms">
          <li>
            <a href="https://www.mozilla.org/privacy/websites/">Privacy</a>
          </li>
          <li>
            <a
              href="https://chat.mozilla.org/#/room/#glean-dictionary:mozilla.org"
              >Chat</a
            >
          </li>
          <li>
            <a href="https://github.com/mozilla/glean-dictionary/issues"
              >Issues</a
            >
          </li>
          <li>
            <a href="https://github.com/mozilla/glean-dictionary">Source</a>
          </li>
        </ul>
        <p>
          Data last updated on
          <a href="https://protosaur.dev/mps-deploys/">{lastUpdatedTime}</a>.
          Built from revision
          <a href="https://github.com/mozilla/glean-dictionary/tree/{rev}"
            >{rev.substring(0, 10)}</a
          >.
        </p>
        <p>
          The Glean Dictionary is built by Mozilla Data. Our vision is to build
          a sustainable, global and independent Mozilla that ethically leverages
          data for the common good. For more information, see the
          <a href="https://telemetry.mozilla.org">Telemetry Portal</a>
          or
          <a href="https://blog.mozilla.org/data/">read our blog</a>.
        </p>
        <p class="mzp-c-footer-license">
          © 2020 - 2023 Mozilla and other contributors.
        </p>
      </div>
    </nav>
  </div>
</footer>

<style lang="scss">
  @import "@mozilla-protocol/core/protocol/css/components/footer";
</style>
